.superlite-product-card_stroke__wgFDj {
  position: relative;
}

.superlite-product-card_stroke__wgFDj::after {
  content: '';
  position: absolute;
  top: -35%;
  left: -11%;
  width: 120%;
  page-break-after: always;
  height: 100%;
  background: url(https://gwatco-res.cloudinary.com/image/upload/v1680056080/new-website/static/homepage/stroke-blue.svg)
    no-repeat bottom;
  margin: auto 0;
}

.reviews-section_stroke__Ea1YH{
    position: relative;
}

.reviews-section_stroke__Ea1YH::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: -5px;
    width: 128%;
    page-break-after: always;
    height: 100%;
    background: url(https://gwatco-res.cloudinary.com/image/upload/v1680056080/new-website/static/homepage/stroke.svg) no-repeat bottom;
    margin: auto 0;
}
.search-panel_wrapper__jt738 {
  background-image: url('https://res.cloudinary.com/gwatco/image/upload/w_800,dpr_auto/q_40/new-website/static/homepage/banner.jpg');
  background-size: cover;
  background-position: center;
}
@media (min-width: 640px) {
  .search-panel_wrapper__jt738 {
    background-image: url('https://res.cloudinary.com/gwatco/image/upload/t_banner-xl,dpr_auto/q_40/new-website/static/homepage/banner-test2.webp');
    background-size: cover;
    background-position: bottom;
  }
}

/*@screen lg {*/
/*  background-position: 0 -10px;*/
/*}*/

.search-panel_panel__FmHE4 {
  box-shadow: 0 0 25px 2px rgba(0 0 0 / 20%);
}

.search-panel_activeTab__8ZhIi {
  position: relative;
  --tw-text-opacity: 1;
  color: rgba(0, 131, 160, var(--tw-text-opacity));
}
.search-panel_activeTab__8ZhIi::after {
  content: '';
  border-bottom: 2px solid #0083a0;
  position: absolute;
  bottom: -0.1rem;
  left: 0;
  right: 0;
  width: 100%;
}

.search-panel_activeSvg__nUKqK {
  filter: invert(0.31) sepia(1) saturate(100) hue-rotate(191deg);
}

.search-panel_searchPanel__4R0_A p {
    text-align: left;
    font-size: 0.875rem;
    line-height: 0.875rem;
    line-height: 1rem
}

