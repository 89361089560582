.stroke {
  position: relative;
}

.stroke::after {
  content: '';
  position: absolute;
  top: -35%;
  left: -11%;
  width: 120%;
  page-break-after: always;
  height: 100%;
  background: url(https://gwatco-res.cloudinary.com/image/upload/v1680056080/new-website/static/homepage/stroke-blue.svg)
    no-repeat bottom;
  margin: auto 0;
}
