.stroke{
    position: relative;
}

.stroke::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: -5px;
    width: 128%;
    page-break-after: always;
    height: 100%;
    background: url(https://gwatco-res.cloudinary.com/image/upload/v1680056080/new-website/static/homepage/stroke.svg) no-repeat bottom;
    margin: auto 0;
}